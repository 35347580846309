// axios
import axios from '../axios.js'
import router from '../router'
import store from './index'

axios.interceptors.request.use(
  async function (config) {
    console.log('Log For LoDING')
    // Do something before request is sent
    await store.dispatch('apiLoading', true)
    const { accessToken } = store.getters
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  async function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  async function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    await store.dispatch('apiLoading', false)
    return response
  },
  async function (error) {
    await console.log('test error', error.response.status)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // const originalRequest = error.config
    if (error.response.status === 401) {
      await store.dispatch('logout')
      await store.dispatch('apiLoading', false)
      this.$router.push({ name: "home" }).catch(() => {});
      return Promise.reject(error)
    }
    await store.dispatch('apiLoading', false)
    return Promise.reject(error)
  }
)

const actions = {
  apiLoading: async (payload) => {
    const appLoading = document.getElementById('loading-bg')
    await document.addEventListener('DOMContentLoaded', (payload) => {
      appLoading.style.display = payload ? 'block' : 'none'
      appLoading.style.zIndex = payload ? '9999999' : '0'
    })
    if (payload) {
      appLoading.style.display = 'block'
      appLoading.style.zIndex = '9999999'
    }
    appLoading.style.display = 'none'

    //console.log('payload ==>', commit)
    //commit("SET_LOADING", payload);
  },
  hideCart: async ({ commit }) => {
    commit('SET_CART_HIDE', false)
  },
  sohwCart: async ({ commit }) => {
    commit('SET_CART_SHOW', true)
  },
  setPage: async ({ commit }, payload) => {
    commit('SET_PAGE', payload)
  },
  sohwMobileMenu: async ({ commit }) => {
    commit('SET_MB_MENU_SHOW', true)
  },
  sohwMobileMenuHide: async ({ commit }) => {
    commit('SET_MB_MENU_SHOW', false)
  },
  nullCart: async ({ commit }) => {
    commit('SET_CART_NULL')
  },
  addToBag: ({ commit }, payload) => {
    console.log('Add to card', payload)
    commit('SET_TO_BAG', payload)
  },
  async addQty({ commit }, payload) {
    console.log('Action payload==>', payload)
    await commit('UPDATE_CART', payload)
  },
  async deductQty({ commit }, payload) {
    await commit('DEDUCT_CART', payload)
  },
  async removeFromCart({ commit }, payload) {
    await commit('REMOVE_FROM_CART', payload)
  },
  clearSiteUrl: async ({ commit }) => {
    commit('SET_SITE_URL', null)
  },
  async setSerchVal({ commit }, payload) {
    await commit('SET_PRICE_SEARCH_VAL', payload)
  },
  getSiteUrl: async ({ commit }, payload) => {
    //await commit("SET_LOADING", true);
    try {
      await axios.get(`site-uri/${payload}`).then((response) => {
        commit('SET_SITE_URL', response.data.data)
        //commit("SET_LOADING", false);
      })
    } catch (e) {
      //commit("SET_LOADING", false);
    }
  },
  getCatagory: async ({ commit, state }, payload) => {
    //await commit("SET_LOADING", true);
    // console.log('form get category action', route)
    /*console.log("action slug----", state.category.slug)
    console.log("payload slug---", router.apps[0]._route.params.slug)*/
    console.log("===", payload)
    try {
      await axios.get(`${payload}`).then((response) => {

        let products = [];
        let allProduct = [];
        if (state.category !== null) {
          if (state.category.slug === router.apps[0]._route.params.slug) {
            let singleProducts = response.data.data.products.filter((e) => e.product_type===1 || e.product_type===3);
            let groupProducts = response.data.data.products.filter((e) => e.product_type===2);
            allProduct.push(...groupProducts);
            if(singleProducts){
              products = singleProducts.filter((e) => e.product_price.length > 0);
              allProduct.push(...products);
            }
            response.data.data.products = allProduct;
            console.log("SET_CATEGORY", response.data.data);
            commit('SET_CATEGORY', response.data.data);
          } else {
            commit('SET_CATEGORY', response.data.data)
          }
        } else {
          commit('SET_CATEGORY', response.data.data)
        }
      })
    } catch (e) {
      //commit("SET_LOADING", false);
    }
  },
  getCategories: async ({ commit }) => {
    //await commit("SET_LOADING", true);
    try {
      await axios.get(`categories`).then((response) => {
        commit('SET_CATEGORIES', response.data.data)
        //commit("SET_LOADING", false);
      })
    } catch (e) {
      //commit("SET_LOADING", false);
    }
  },
  getSiteSettings: async ({ commit }) => {
    //await commit("SET_LOADING", true);
    try {
      await axios.get(`site-setting`).then((response) => {
        commit('SET_SITE_SETTINGS', response.data.data)
        //commit("SET_LOADING", false);
      })
    } catch (e) {
      //commit("SET_LOADING", false);
    }
  },
  getBrands: async ({ commit }) => {
    //await commit("SET_LOADING", true);
    try {
      await axios.get(`brands`).then((response) => {
        commit('SET_BRANDS', response.data.data)
        //commit("SET_LOADING", false);
      })
    } catch (e) {
      //commit("SET_LOADING", false);
    }
  },
  getBrand: async ({ commit, state }, payload) => {
    //await commit("SET_LOADING", true);
    try {
      await axios.get(`${payload}`).then((response) => {
        let brandProducts = [];
        let allBrandProduct = [];
        if (state.brand !== null) {
          if (state.brand.slug === router.apps[0]._route.params.slug) {
            commit('SET_BRAND_PRODUCTS', response.data.data)
          } else {
            let bSingleProducts = response.data.data.products.filter((e) => e.product_type===1 || e.product_type===3);
            let bGroupProducts = response.data.data.products.filter((e) => e.product_type===2);
            allBrandProduct.push(...bGroupProducts);
            if(bSingleProducts){
              brandProducts = bSingleProducts.filter((e) => e.product_price.length > 0);
              allBrandProduct.push(...brandProducts);
            }
            response.data.data.products = allBrandProduct;
            commit('SET_BRAND', response.data.data);

            console.log("#####---=>", response.data.data);
          }
        } else {
          commit('SET_BRAND', response.data.data)
        }

        //commit("SET_BRAND", response.data.data);
        //commit("SET_LOADING", false);
      })
    } catch (e) {
      //commit("SET_LOADING", false);
    }
  },
  getBrandByTextFiltering: async ({ commit }, payload) => {
    ////await commit("SET_LOADING", true);
    try {
      await axios.get(`brands?keyword=${payload}`).then((response) => {
        commit('SET_BRANDS_TEXT_FILTER', response.data.data)
        ////commit("SET_LOADING", false);
      })
    } catch (e) {
      ////commit("SET_LOADING", false);
    }
  },
  getCategoryByTextFiltering: async ({ commit }, payload) => {
    ////await commit("SET_LOADING", true);
    try {
      await axios.get(`/category-filter-in-category/${payload}`).then((response) => {
        console.log('^^^^^^^^^^^^', response.data.data);
        commit('SET_CATEGORY_TEXT_FILTER', response.data.data)
        ////commit("SET_LOADING", false);
      })
    } catch (e) {
      ////commit("SET_LOADING", false);
    }
  },
  getProductBySearch: async ({ commit }, payload) => {
    ////await commit("SET_LOADING", true);
    try {
      await axios.get(`/product/search?keyword=${payload}`).then((response) => {
        let arrRes = response.data.data.products.concat(
          response.data.data.releted_products
        )
        console.log('arra res=> ', response.data.data)
        console.log('arra res2=> ', arrRes)
        commit('SET_PRODUCT_SEARCH_FILTER', arrRes)
        ////commit("SET_LOADING", false);
      })
    } catch (e) {
      ////commit("SET_LOADING", false);
    }
  },
  getProductForSearchPage: async ({ commit }, payload) => {
    ////await commit("SET_LOADING", true);
    try {
      await axios.get(`/product/search?keyword=${payload}`).then((response) => {
        console.log('arra res=> ', response.data.data)
        commit('SET_PRODUCT_SEARCH_PAGE', response.data.data)
        ////commit("SET_LOADING", false);
      })
    } catch (e) {
      ////commit("SET_LOADING", false);
    }
  },

  getOrderDetails: async ({ commit }, payload) => {
    ////await commit("SET_LOADING", true);
    try {
      await setTimeout(()=>{
         axios.get(`/order/${payload}`).then((response) => {
          console.log('======= res=> ', response.data.data)
          commit('SET_ORDER_DETAILS', response.data.data)
          ////commit("SET_LOADING", false);
        })
      }, 100)

    } catch (e) {
      ////commit("SET_LOADING", false);
    }
  },
  cancelOrder: async ({ commit }, payload) => {
    ////await commit("SET_LOADING", true);
    console.log('paylodddd ===>> ', payload)
    try {
      await axios.get(`/order-cancel/${payload}`).then((response) => {
        console.log('======= Cancel res=> ', response.data.data)
        commit('SET_CANCEL_ORDER', response.data.data)
        ////commit("SET_LOADING", false);
      })
    } catch (e) {
      ////commit("SET_LOADING", false);
    }
  },
  getProductBySearchClear: async ({ commit }) => {
    try {
      commit('SET_PRODUCT_SEARCH_FILTER', [])
    } catch (e) {
      console.log()
    }
  },
  getBrandByTextFilteringHome: async ({ commit }, payload) => {
    await commit('SET_LOADING', true)
    try {
      await axios.get(`brands?keyword=${payload}`).then((response) => {
        commit('SET_BRANDS_TEXT_FILTER_HOME', response.data.data)
        commit('SET_LOADING', false)
      })
    } catch (e) {
      commit('SET_LOADING', false)
    }
  },
  getProductDetails: async ({ commit }, payload) => {
    await commit("SET_LOADING", true);
    try {
      await axios.get(`product/${payload}`).then((response) => {
        commit('SET_PRODUCT_DETAILS', response.data.data)
        console.log("All Product Details =", response.data.data)
        commit('SET_ZOOM_IMAGE', response.data.data.product_images)
        commit("SET_LOADING", false);
      })
    } catch (e) {
      commit("SET_LOADING", false);
    }
  },
  getSingleProductClear: async ({ commit }) => {
    commit('SET_SINGLE_PRODUCT', [])
  },
  getSingleProduct: async ({ commit }, payload) => {
    try {
      await axios.get(`single-product/${payload}`).then((response) => {
        const finalData = JSON.parse(JSON.stringify(response.data.data))
        console.log('Feach Data -', typeof finalData)
        commit('SET_SINGLE_PRODUCT', finalData)
      })
    } catch (e) {
      //commit("SET_LOADING", false);
    }
  },
  setZoomimage: async ({ commit }, payload) => {
    commit('SET_ZOOM_IMAGE', payload)
  },
  setLocaitons: async ({ commit }) => {
    try {
      await axios.get(`locations`).then((response) => {
        commit('SET_LOCATIONS', response.data.data)
      })
    } catch (e) {
      //commit("SET_LOADING", false);
    }
  },
  getHome: async ({ commit }) => {
    //await commit("SET_LOADING", true);
    try {
      await axios.get(`home`).then((response) => {
        commit('SET_HOME', response.data.data)
        //commit("SET_LOADING", false);
      })
    } catch (e) {
      //commit("SET_LOADING", false);
    }
  },
  getsaleNow: async ({ commit }) => {
    //await commit("SET_LOADING", true);
    try {
      await axios.get(`product-sale-now`).then((response) => {
        commit('SET_SALE_NOW', response.data.data)
        //commit("SET_LOADING", false);
      })
    } catch (e) {
      //commit("SET_LOADING", false);
    }
  },
  getTopSelling: async ({ commit }) => {
    //await commit("SET_LOADING", true);
    try {
      await axios.get(`product-top-selling`).then((response) => {
        commit('SET_TOP_SELLING', response.data.data)
        //commit("SET_LOADING", false);
      })
    } catch (e) {
      //commit("SET_LOADING", false);
    }
  },
  logout: async ({ commit }) => {
    //commit("SET_LOADING", true);
    commit('SET_ME', null)
    commit('SET_CUSTOMER', null)
    console.log("logout")
    this.$router.push({ name: "home" }).catch(() => {});
    //commit("SET_CUSTOMER_WISHLIST", null);
    //window.localStorage.vuex.customer = null;
    //location.reload();
    // router.push("/customer");
    // try {
    //   await axios.get(`logout/`).then((response) => {
    //     console.log(response);
    //     commit("SET_LOADING", false);
    //     commit("SET_CUSTOMER", null);
    //     location.reload();
    //   });
    // } catch (e) {
    //   window.localStorage.clear();
    //   location.reload();
    //   commit("SET_LOADING", false);
    // }
    //commit("SET_LOADING", false);
  },
  login: async ({ commit }, payload) => {
    //await commit("SET_LOADING", true);
    try {
      await axios.post(`login/`, payload).then((response) => {
        commit('SET_CUSTOMER', response.data)
        console.log('login', response.data)
      })
    } catch (e) {
      //console.log('login error==', e.response.data)
      //return e.response.data
      //commit("SET_LOADING", false);
    }
  },
  loginWithSocial: async ({ commit }, payload) => {
    console.log("Logiiiiiiii")
    //await commit("SET_LOADING", true);https://savershall.com/sh_backapi/api/sociallogin
    try {
      await axios.post(`/sociallogin`, payload).then((response) => {
        commit('SET_CUSTOMER', response.data)
        console.log('login', response.data)
        router.push('/customer-dashboard')
      })
    } catch (e) {
      return e.response.data
      //commit("SET_LOADING", false);
    }
  },
  disableId: async ({ commit }, payload) => {
      await axios.post('/customer/disable', payload); // Replace with your API endpoint
      commit('CLEAR_CUSTOMER_DATA');
  },
  registration: async ({ commit }, payload) => {
    //await commit("SET_LOADING", true);
    try {
      await axios.post(`register/`, payload).then((response) => {
        commit('SET_CUSTOMER', response.data)
        console.log('Registration Customer=', response.data)
        //commit("SET_LOADING", false);
        // router.go(1);
        //router.push("/customer-dashboard");
        //location.reload();
      })
    } catch (e) {
      return e.response.data
      //commit("SET_LOADING", false);
    }
  },
  news: async ({ commit }, payload) => {
    console.log(commit)
    //await commit("SET_LOADING", true);
    try {
      await axios.post(`news-leter/`, payload).then((response) => {
        console.log("ddddddd",response.data)
        commit("SET_NEWSLETTER", response.data.message);
        //commit("SET_LOADING", false);
      })
    } catch (e) {
      commit("SET_NEWSLETTER", e.response.data.message);
      console.log("aaaaaaa",e.response.data)
      //commit("SET_LOADING", false);
    }
  },
  billing: async ({ commit }, payload) => {
    //await commit("SET_LOADING", true);
    console.log('Action ==>', payload)
    try {
      await axios.post(`orders/`, payload).then((response) => {
        console.log('Action Response ==>', response.data)
        if(response.data.data.backurl){

          commit('SET_CART_NULL');
          window.location.href = response.data.data.backurl;
        }
        //router.push('/')
        //alert('Yor order has been place successfully ')
        commit('SET_ORDERS', response.data.data)
        //commit("SET_LOADING", false);
      })
    } catch (e) {
      return e.response.data
      //commit("SET_LOADING", false);
    }
  },

  getMe: async ({ commit }) => {
    ////await commit("SET_LOADING", true);
    try {
      await axios.get(`me/`).then((response) => {
        commit('SET_ME', response.data.data)
        ////commit("SET_LOADING", false);
      })
    } catch (e) {
      ////commit("SET_LOADING", false);
    }
  },
  getcustomerSummery: async ({ commit }) => {
    await commit('SET_LOADING', true)
    try {
      await axios.get(`orders/`).then((response) => {
        console.log(response.data)
        commit('SET_CUSTOMER_SUMMERY', response.data.data)
        commit('SET_LOADING', false)
      })
    } catch (e) {
      commit('SET_LOADING', false)
    }
  },
  getCustomerPoints: async ({ commit }) => {
    await commit('SET_LOADING', true)
    try {
      await axios.get(`/customer-points`).then((response) => {
        console.log("Customer Points =", response.data)
        commit('SET_CUSTOMER_POINTS', response.data.data)
        commit('SET_LOADING', false)
      })
    } catch (e) {
      commit('SET_LOADING', false)
    }
  },
  addToWishlist: async ({ commit }, payload) => {
    console.log(commit)
    ////await commit("SET_LOADING", true);
    try {
      console.log(payload)
      await axios.post(`wishlists/`, payload).then((response) => {
        ////commit("SET_LOADING", false);
        console.log(response)
      })
    } catch (e) {
      ////commit("SET_LOADING", false);
    }
  },
  getCustomerWishlist: async ({ commit }) => {
    ////await commit("SET_LOADING", true);
    try {
      await axios.get(`wishlist/`).then((response) => {
        commit('SET_CUSTOMER_WISHLIST', response.data.data)
        ////commit("SET_LOADING", false);
      })
    } catch (e) {
      ////commit("SET_LOADING", false);
    }
  },
  removeWishlist: async ({ commit }, payload) => {
    ////await commit("SET_LOADING", true);
    try {
      await axios.get(`/remove-wishlist/${payload}`).then((response) => {
        console.log('re===,', commit)
        console.log('re===,', response.data)
        //commit("SET_CUSTOMER_WISHLIST", response.data.data);
        ////commit("SET_LOADING", false);
      })
    } catch (e) {
      ////commit("SET_LOADING", false);
    }
  },
  soldOutNotifyMe: async ({ commit }, payload) => {
    console.log(commit)
    ////await commit("SET_LOADING", true);
    try {
      await axios.post(`stock-notice/`, payload).then((response) => {
        ////commit("SET_LOADING", false);
        console.log(response)
      })
    } catch (e) {
      ////commit("SET_LOADING", false);
      return e.response.data
    }
  },
  getShippingCharge: async ({ commit }, payload) => {
    //await commit("SET_LOADING", true);
    try {
      await axios.post(`/check-shipping`, payload).then((response) => {
        commit('SET_SHIPPING_CHARGE', response.data.data)
        console.log('SET_SHIPPING_CHARGE', response.data.data)
        //commit("SET_LOADING", false);
      })
    } catch (e) {
      //commit("SET_LOADING", false);
    }
  },
  validPurchaseItemsForDigitalMarketers: async ({ commit }, payload) => {
    //await commit("SET_LOADING", true);
    try {
      commit('SET_PURCHASE_ITEMS', payload)
    } catch (e) {
      //commit("SET_LOADING", false);
    }
  },
  loadMorePage: async ({ commit }, payload) => {
    //await commit("SET_LOADING", true);
    try {
      commit('SET_LOAD_MORE_PAGE', payload)
    } catch (e) {
      //commit("SET_LOADING", false);
    }
  },
  
}
export default actions
